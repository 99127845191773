<template>
  <div>
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>     
      <v-data-table
        :headers="headers"
        :items="students"
        :items-per-page="-1"
        dense
        >
        <!-- Email -->
        <template v-slot:item.email="{ item }">
          <router-link :to="`/student/${item.id}`" class="no-decoration">
            {{ item.email || 'unknown' }}
          </router-link>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

export default {
  props: ['orgId'],

  data () {
    return {
      headers: [
        {
          sortable: false,
          text: 'Email',
          value: 'email'
        }
      ],

      students: null
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.students && this.students.length > 0
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      students: firebase.firestore()
        .collection('settings')
        .where('organisation', '==', this.orgId)
    }
  }
}
</script>

<style>

</style>