<template>
  <div>
    <!-- Loading bar -->
    <v-progress-linear v-if="!loaded" color="green" :indeterminate="true"></v-progress-linear>

    <div v-else>
      <v-data-table
        :headers="headers"
        :items="licenses"
        :items-per-page="-1"
        dense
        sort-by="email"
        >
        <!-- Toolbar -->
        <template v-slot:top>
          <v-toolbar dense elevation="0" flat>
            <v-spacer></v-spacer>

            <v-btn
              @click="createDialog = true"
              class="float-right mb-2"
              color="green"
              x-small
              >
              Create
            </v-btn>
          </v-toolbar>
        </template>

        <!-- Email -->
        <template v-slot:item.email="{ item }">
          <router-link :to="`/student/${item.id}`" class="no-decoration">
            {{ item.email || 'unknown' }}
          </router-link>
        </template>

        <!-- Assigned On -->
        <template v-slot:item.assignedOn="{ item }">
          {{ formatDate(item.assignedOn.toDate()) }}
        </template>

        <!-- Expiry -->
        <template v-slot:item.expiry="{ item }">
          {{ formatDate(item.expiry.toDate()) }}
        </template>

        <!-- Actions -->
        <template v-slot:item.actions="{ item }">
          <!-- Edit -->
          <v-btn
            @click="licenseId = item.id; editDialog = true"
            color="green"
            icon
            small
            >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <!-- Delete -->
          <v-btn
            @click="licenseId = item.id; deleteDialog = true"
            color="red"
            icon
            small
            >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <!-- Dialogs -->
    <Create v-model="createDialog" :orgId="orgId" />
    <Delete v-model="deleteDialog" :licenseId="licenseId" />
    <Edit v-model="editDialog" :licenseId="licenseId" />
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'
import moment from 'moment'

// components
import Create from '@/components/organization/licenses/Create'
import Delete from '@/components/organization/licenses/Delete'
import Edit from '@/components/organization/licenses/Edit'

export default {
  props: ['orgId'],

  data () {
    return {
      // the three dialog boxes
      createDialog: false,
      deleteDialog: false,
      editDialog: false,

      // table headers
      headers: [
        {
          sortable: false,
          text: 'License Id',
          value: 'id'
        },

        {
          sortable: false,
          text: 'User Email',
          value: 'email'
        },

        {
          sortable: false,
          text: 'User ID',
          value: 'uid'
        },

        {
          sortable: false,
          text: 'SKU',
          value: 'sku'
        },

        {
          sortable: false,
          text: 'Assigned On',
          value: 'assignedOn'
        },

        {
          sortable: false,
          text: 'Expires On',
          value: 'expiry'
        },

        {
          sortable: false,
          text: 'Actions',
          value: 'actions'
        }
      ],

      // placeholder for deleting or editing a given license
      licenseId: null,
      
      // array holding the licenses
      licenses: null
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.licenses && this.licenses.length > 0
    }
  },

  methods: {
    /* 
     *
     */
    formatDate (dt) {
      return moment(dt).format('MMM Do YY')
    }
  },

  /*
   * Firestore Connector
   */
  firestore () {
    return {
      licenses: firebase.firestore()
        .collection('licenses')
        .where('orgId', '==', this.orgId)
    }
  },

  components: {
    Create,
    Delete,
    Edit
  }
}
</script>

<style>

</style>